@font-face {
    font-family: 'RL2';
    src: url('../fonts/RL2-Regular/subset-RL2-Regular.woff2') format('woff2'),
    url('../fonts/RL2-Regular/subset-RL2-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RL2';
    src: url('../fonts/RL2-Medium/subset-RL2-Medium.woff2') format('woff2'),
    url('../fonts/RL2-Medium/subset-RL2-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'RL2';
    src: url('../fonts/RL2-Bold/subset-RL2-Bold.woff2') format('woff2'),
    url('../fonts/RL2-Bold/subset-RL2-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'RL2';
    src: url('../fonts/RL2-Bold/subset-RL2-Bold.woff2') format('woff2'),
    url('../fonts/RL2-Bold/subset-RL2-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Light.otf') format('opentype'),
    url('../fonts/gotham/Gotham-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Medium.otf') format('opentype'),
    url('../fonts/gotham/Gotham-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/GothamBold.otf') format('opentype'),
    url('../fonts/gotham/GothamBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}