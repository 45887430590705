/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: RL2, sans-serif;
	--fontFamilyGotham: Gotham, sans-serif;

	--primaryYellow: #FFC72C;
	--secondaryRed: #C8102E;
	--yellowTint: #FDDA25;
	--baseBlack: #000000;
	--disabledGrey: #B0B0B0;

	--gradientStart: #000000;
	--gradientStop: #000000;
	
	--accent1: #FF2261;
	--accent2: #FF3C14;
	--accent3: #00E68C;
	--accent4: #3230BE;
	--accent5: #EBB700;

	--textColor1: #000000;
	--textColor2: #fff;

	--background: #000000;
	--backgroundPopUpHeader: #F6F6F6;
	--PopUpHeader: #4D4D4D;
	--femaleColor: #0093B2;
	--maleColor: #00E188;
}

html, body {
	background: var(--background);
	min-height: 100%;
	height: 100%;
	color: var(--textColor1);
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--fontFamilyBase);
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
    font-weight: 500;
}

h1 {
	font-size: 44px;
	line-height: 46px;
}

h2 {
    font-size: 36px;
	line-height: 40px;
}

h3 {
    font-size: 28px;
	line-height: 32px;
}

h4 {
    font-size: 24px;
}

input,
button,
select {
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

/* Preload Landing images */
body::after{
	/* hide images */
	position: absolute;
	width:0;
	height:0;
	overflow:hidden;
	z-index:-1;

	/* load images */
	
}

.no-mob{
	display:block;
}
.nopad {
	padding:0 !important;
}

.settings nav {
	width: calc(100% - 460px);
}

@media (max-width: 640px) {
	.no-mob{
		display:none;
	}
	.nopadmob {
		padding:0 !important;
	}
	.settings nav {
		width: 100%;
	}
}
